import React from 'react';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';

import { QueryFragments } from '@querys/queryFragments'; // eslint-disable-line

// Components
import { PageMeta } from '@components/pageMeta';
import ModuleZone from '~moduleZone';
import Layout from '@components/layout';

const PageTemplate = ({ preview = false, data, previewDoc }) => {
  const page = preview ? previewDoc : data?.page;
  const { modules, seo, reverseHeader, hideFromIndex } = page || {};
  const { search } = useLocation();

  //grab the utm_source from the url if it exists
  const urlParams = new URLSearchParams(search);
  const utmSource = urlParams.get('utm_source');

  return (
    <Layout reversedHeader={reverseHeader} utmSource={utmSource}>
      <PageMeta {...seo} hideFromIndex={hideFromIndex} />
      {modules && <ModuleZone {...modules} />}
    </Layout>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query standardPageQuery($slug: String!) {
    page: sanityModularPage(slug: { current: { eq: $slug } }) {
      seo {
        ...SEO
      }
      hideFromIndex
      modules {
        ...PageModules
      }
      reverseHeader
    }
  }
`;
